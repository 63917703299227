const S_MULTIPLE = 0;
const S_CREATED = 1;
const S_CHECKED_IN = 2;
const S_CHECKED_OUT = 3;
const S_ABSENT = 4;

const visitStatuses = {
  [S_MULTIPLE]: {
    icon: "#cx-vis1-select-all",
    label: "select-all",
    status: S_MULTIPLE
  },
  [S_CREATED]: {
    icon: "#cx-vis1-expected",
    label: "created",
    status: S_CREATED
  },
  [S_CHECKED_IN]: {
    icon: "#cx-vis1-check-in",
    label: "checked-in",
    status: S_CHECKED_IN
  },
  [S_CHECKED_OUT]: {
    icon: "#cx-vis1-check-out",
    label: "checked-out",
    status: S_CHECKED_OUT
  },
  [S_ABSENT]: {
    icon: "#cx-vis1-paused",
    label: "absent",
    status: S_ABSENT
  }
};

/**
 * Default value for VM table if field is not set or empty. This could be anything "-" or "&nbsp;" etc.
 */
const tableFieldDefaultValue = "-";

export default visitStatuses;

export {
  S_MULTIPLE,
  S_CREATED,
  S_CHECKED_IN,
  S_CHECKED_OUT,
  S_ABSENT,
  tableFieldDefaultValue
};
