<template>
  <div class="clebex-widget-item content">
    <div
      v-for="desk in items"
      :key="desk.id"
      class="clebex-widget-plan-wrapper"
      @click="
        $router.push({
          name: 'r_visitor-management-reception-desk',
          params: { receptionDeskId: desk.itemable.id }
        })
      "
    >
      <div class="clebex-widget-plan-text">
        {{ desk.itemable.name }}
      </div>
      <section class="widget-summary">
        <section
          v-for="(visitorStatus, key, index) in visitStatuses"
          :key="index"
        >
          <Popper
            hover
            openDelay="500"
            :content="
              displayLabelName(
                'visitor-management',
                'reception-desks',
                visitorStatus.label
              )
            "
          >
            <div class="widget-summary-item">
              <icon :icon="visitorStatus.icon" width="55" height="28" />
              <div class="widget-count">
                {{
                  key != 0
                    ? desk.itemable.visit_statuses[key]
                    : desk.itemable.visits
                }}
              </div>
            </div>
          </Popper>
        </section>
        <Popper
          hover
          openDelay="500"
          :content="
            displayLabelName('visitor-management.reception-desks.license-plate')
          "
        >
          <div class="widget-summary-item">
            <icon icon="#cx-vis1-parking-12x12" width="55" height="28" />
            <div class="widget-count">
              {{ desk.itemable.cars }}
            </div>
          </div>
        </Popper>
        <Popper
          hover
          openDelay="500"
          :content="
            displayLabelName('visitor-management.reception-desks.notes')
          "
        >
          <div class="widget-summary-item">
            <icon icon="#cx-vis1-warning-12x12" width="55" height="28" />
            <div class="widget-count">
              {{ desk.itemable.notes }}
            </div>
          </div>
        </Popper>
        <Popper
          hover
          openDelay="500"
          :content="
            displayLabelName('visitor-management.reception-desks.disability')
          "
        >
          <div class="widget-summary-item">
            <icon icon="#cx-vis1-disability-12x12" width="55" height="28" />
            <div class="widget-count">
              {{ desk.itemable.disabilities }}
            </div>
          </div>
        </Popper>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import visitStatuses from "@/services/consts/modules/visitor-management/statuses";
import { subscribe, unsubscribe } from "@/services/ws-service";
import { wsEvents } from "@/services/constants";
import Popper from "vue3-popper";

export default {
  name: "WidgetsVisitorManagementContent",
  components: {
    Popper
  },
  data() {
    return {
      visitStatuses: visitStatuses,
      channel: null
    };
  },
  created() {
    this.channel = subscribe(
      "receptionDesks",
      [],
      wsEvents.receptionDesk.visitor.changed,
      this.wsCallback
    );
  },
  methods: {
    ...mapActions("cockpit", ["getWidgetItem"]),
    wsCallback(e) {
      const item = this.items.find(el => el.itemable.id === e.receptionDesk.id);
      if (item) {
        this.getWidgetItem({ type: "VISITOR_MANAGEMENT", itemId: item.id });
      }
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  beforeUnmount() {
    if (this.channel) {
      unsubscribe(this.channel);
      this.channel = null;
    }
  }
};
</script>
